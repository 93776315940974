import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { debounceTime, filter, fromEvent, map, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { environment } from '@sbd-doug-frontend/app/environments/environment';
import { DOCUMENT } from '@angular/common';
import { MatOptionSelectionChange } from '@angular/material/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { generateShortcutTooltip } from '@sbd-doug-frontend/shared/lib/platform-detection';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'fuseSearch',
  animations: fuseAnimations,
  styles: `
    .search-bar-input.mat-mdc-autocomplete-trigger {
      border-left: 1px solid var(--fuse-border);
      // border-right: 1px solid var(--fuse-border);
      box-shadow: inset 0px 0px 233px -113px rgba(86, 199, 110, 0.5);

    }

  `
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
  @Input() appearance: 'basic' | 'bar' = 'basic';
  @Input() debounce = 300;
  @Input() minLength = 2;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  opened = false;
  resultSets: any[];
  searchControl: UntypedFormControl = new UntypedFormControl();
  isScreenSmall: boolean;
  shortcutTooltip = generateShortcutTooltip(['f']);

  private _matAutocomplete: MatAutocomplete;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _httpClient: HttpClient,
    private _renderer2: Renderer2,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    @Inject(DOCUMENT) private _document: any,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'search-appearance-bar': this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened': this.opened
    };
  }

  /**
   * Setter for bar search input
   *
   * @param value
   */
  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef) {
    // If the value exists, it means that the search input
    // is now in the DOM, and we can focus on the input..
    if (value) {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {

        // Focus to the input element
        value.nativeElement.focus();
      });
    }
  }

  /**
   * Setter for mat-autocomplete element reference
   *
   * @param value
   */
  @ViewChild('matAutocomplete')
  set matAutocomplete(value: MatAutocomplete) {
    this._matAutocomplete = value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Appearance
    if ('appearance' in changes) {
      // To prevent any issues, close the
      // search after changing the appearance
      this.close();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Listen for shortcuts
    fromEvent(this._document, 'keydown')
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter<KeyboardEvent>(event =>
          (event.altKey) // alt / option
          && (event.code === 'KeyF') // 'e'
        )
      )
      .subscribe((event: KeyboardEvent) => {
        if (event.code === 'KeyF') {
          if (!this.opened) {
            this.open();
            this.barSearchInput?.nativeElement.focus();
          } else {
            this.close();
          }
        }
      });
    // Subscribe to the search field value changes
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {

          // Set the resultSets to null if there is no value or
          // the length of the value is smaller than the minLength
          // so the autocomplete panel can be closed
          if (!value || value.length < this.minLength) {
            this.resultSets = null;
          }

          // Continue
          return value;
        }),
        // Filter out undefined/null/false statements and also
        // filter out the values that are smaller than minLength
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        this._httpClient.get(environment.apiBaseUrl + '/api/v4/search/basic', { params: { q: value } })
          .subscribe((resultSets: any) => {
            const requests = resultSets.data.search.search_results.filter(result => result.result_type === 'request');
            const searchResults = [
              {
                label: 'Quote Requests',
                results: requests,
                id: 'quote_requests'
              }
            ];

            // Store the result sets
            this.resultSets = searchResults;

            // Execute the event
            this.search.next(searchResults);
          });
      });


    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On keydown of the search input
   *
   * @param event
   */
  onKeydown(event: KeyboardEvent): void {
    // Escape
    if (event.code === 'Escape') {
      // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
      if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
        this.close();
      }
    }
  }

  /**
   * Open the search
   * Used in 'bar'
   */
  open(): void {
    // Return if it's already opened
    if (this.opened) {
      return;
    }

    // Open the search
    this.opened = true;
  }

  /**
   * Close the search
   * * Used in 'bar'
   */
  close(): void {
    // Return if it's already closed
    if (!this.opened) {
      return;
    }

    // Clear the search input
    this.searchControl.setValue('');

    // Close the search
    this.opened = false;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }


  formatUri(result: any) {
    return environment.dougV2BaseUrl + result.result_uri;
  }

  onEnter(event: any, result: any) {
    if (result && Object.hasOwn(result, 'result_uri')) {
      window.location.href = this.formatUri(result);
    }
  }

  // TODO: Damian we can't use document:click here. It binds to the whole page and causes all links to throw the "popup blocked" error messages
  // @HostListener('document:click', ['$event'])
  openPageOnKeyHold(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      event.stopPropagation();
      const link = (event.target as HTMLElement).closest('a')?.getAttribute('href');
      if (link) {
        window.open(link, '_blank');
      }
    }
  }

}
